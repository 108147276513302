/* styles.css */

body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
  }
  
  .navbar {
    background-color: #ffffff;
    color: white;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left:0;
    right:0;
    top:0;
  }
  
  .navbar .menu-button {
    display: none;
  }
  
  .navbar .logo {
    display: flex;
    align-items: center;
  }

  .logo {
    color:'red'
  }
  
 
  
  .drawer {
    width: 250px;
    background-color: #f4f4f4;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow: auto;
    transition: transform 0.3s ease;
    transform: translateX(0);
  }
  
  .drawer.closed {
    transform: translateX(-100%);
  }
  
  .drawer .close-button {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .drawer ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .drawer ul li {
    padding: 1rem;
    
  }

  .drawer ul li a {
    text-decoration: none;
    color: inherit; 
  }
  
  .main-content {
    margin-left: 250px;
    padding: 1rem;
    transition: margin-left 0.3s ease;
  }
  
  @media (max-width: 768px) {
    .navbar .menu-button {
      display: block;
    }
  
    .drawer {
      width: 100%;
      transform: translateX(-100%);
    }
  
    .drawer.open {
      transform: translateX(0);
    }
  
    .main-content {
      margin-left: 0;
    }
  
    .drawer .close-button {
      display: block;
    }
  }  