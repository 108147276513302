/* Overlay to cover the entire screen */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is on top of other content */
  }
  
  /* Modal content styling */
  .modal-content {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 500px; /* Adjust this value as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  /* Close button styling */
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-close:hover {
    color: red;
  }
  
  /* Responsive adjustments */
  @media (max-width: 600px) {
    .modal-content {
      width: 95%;
      padding: 15px;
    }
  }
  