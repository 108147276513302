.masonry-container {
    padding: 20px;
    max-width: 1200px; /* Set a max-width to ensure it doesn't expand infinitely */
    margin: 0 auto; /* Center the container */
}

.masonry-header {
    font-size: 20px;
    margin-bottom: 10px;
    color: #E64833;
    font-weight: light;
    padding-bottom:15px;
    padding-top:10px;
}

.masonry-grid {
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
}

.masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
}

.masonry-item {
    background: #f5f5f5;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px; /* space between items */
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit text color from parent */
    display: block; /* Ensure the link behaves like a block-level element */
}

.masonry-item:hover {
    background: #e0e0e0; /* Change background on hover */
}
