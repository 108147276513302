/* App.css */
.register-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f9fc;
    padding-top:50px;
  }
  
  .register-form {
    background-color: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
  }
  
  .register-header {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .logo {
    width: 50px;
    height: 50px;
  }
  
  .register-header h1 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
  }
  
  .register-header p {
    color: #666;
    font-size: 0.9rem;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .permission-group {
    margin-bottom: 1rem;
  }
  
  .permission-group label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .permission-group input {
    margin-right: 0.5rem;
  }
  
  .button {
    width: 100%;
    padding: 0.75rem;
    background-color: #00ff00;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #00e600;
  }
  




  .my-masonry-grid {
    display: flex;
    margin-left: -5px; /* gutter size offset */
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 5px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .card {
    background: #fff;
    margin-bottom: 30px;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .card h3 {
    margin: 0 0 10px;
    font-size: 1.2em;
  }
  
  .card p {
    margin: 0;
    color: #333;
  }
  