.procurement-form {
    max-width: 600px;
    /* margin: auto; */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group select,
  .react-datepicker__input-container input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .submit-button {
    width: 130px;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  


  .masonry-container {
    padding: 20px;
    max-width: 1200px; /* Set a max-width to ensure it doesn't expand infinitely */
    margin: 0 auto; /* Center the container */
}

.masonry-header {
    font-size: 30px;
    margin-bottom: 10px;
    color: #060713;
    font-weight: light;
    padding-bottom:15px;
    padding-top:30px;
}

.masonry-grid {
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
}

.masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
}

.masonry-item {
    background: #f5f5f5;
    border: 1px solid #ddd;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px; /* space between items */
    text-decoration: none; /* Remove underline from links */
    color: inherit; /* Inherit text color from parent */
    display: block; /* Ensure the link behaves like a block-level element */
}

.masonry-item:hover {
    background: #e0e0e0; /* Change background on hover */
}

/* for the modal part */

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

.trigger-div {
  background-color: #007bff;
  color: #fff;
  padding: 15px 25px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.trigger-div:hover {
  background-color: #0056b3;
}
